import { wrapCreateBrowserRouter } from '@sentry/react'
import * as React from 'react'
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
  useMatches,
  useParams,
  type NavigateProps,
  type UIMatch,
} from 'react-router-dom'
import { LanguageStringDecoder } from 'src/api/types'
import env from 'src/helpers/env'
import { useAuth } from 'src/hooks/auth/auth'
import { useLocale } from 'src/hooks/locale/locale'
import { useMaintenanceModeState } from 'src/hooks/maintenanceMode'
import AuthenticatedLayout from 'src/views/includes/AuthenticatedLayout'
import LoginPage from 'src/views/pages/Unauthenticated/LoginPage'
import MainRouteLayout from './includes/MainRouteLayout'
import MaintenanceModePage from './pages/MaintenanceModePage'
import AppLoadingPage from './pages/Undetermined/AppLoadingPage'

function MaintenanceModeMiddleware(): React.ReactElement | null {
  const [maintenanceMode] = useMaintenanceModeState()

  if (maintenanceMode) {
    return <MaintenanceModePage />
  }

  return <Outlet />
}

function LocaleMiddleware(): React.ReactElement | null {
  const locale = useLocale()
  const params = useParams()
  const loc = useLocation()
  const matches = useMatches()
  const auth = useAuth()

  const homePageURL =
    auth.state === 'Authenticated'
      ? auth.profile.type === 'UserProfileStudent'
        ? `/${locale}/student`
        : auth.profile.type === 'UserProfileAdministrator'
          ? `/${locale}/administrator`
          : auth.profile.type === 'UserProfileLecturer'
            ? `/${locale}/lecturer`
            : `/${locale}`
      : `/${locale}`

  // if params.locale is not valid, replace it with the current locale
  if (LanguageStringDecoder.value(params.locale) == null) {
    const pathname = params.locale != null ? loc.pathname.slice(params.locale.length + 1) : loc.pathname

    return (
      <Navigate
        replace
        to={{
          hash: loc.hash,
          pathname: `/${locale}${pathname}`,
          search: loc.search,
        }}
      />
    )
  }

  // if the last match is LocaleMiddleware
  if (matches.at(-1)?.id === 'LocaleMiddleware') {
    // show login page if the user is not authenticated
    if (auth.state === 'Unauthenticated') {
      return <LoginPage />
    }

    if (homePageURL !== loc.pathname) {
      return <Navigate replace to={homePageURL} />
    }
  }

  const isAuthenticatedLayout = matches.find((match) => match.id === 'AuthenticatedLayout') != null

  if (isAuthenticatedLayout) {
    if (auth.state === 'Unauthenticated') {
      return <LoginPage />
    }

    if (auth.state === 'Undetermined') {
      return <AppLoadingPage />
    }

    if (auth.state === 'Authenticated') {
      // returns true if the route has a profileType and it is different from the profileType of the user
      const checkProfileType = (match: UIMatch<any, any>): boolean => {
        // checking if the route has a profileType
        if (match.handle?.profileType == null) {
          return false
        }

        // checking if profileType of the route is different from the profileType of the user
        return match.handle.profileType !== auth.profile?.type
      }

      // if the route has a profileType and it is different from the profileType of the user
      // then redirect to the home page
      if (matches.find(checkProfileType) != null) {
        return <Navigate replace to={homePageURL} />
      }
    }
  }

  return <Outlet />
}

function NavigateWithLocale(props: NavigateProps): React.ReactElement | null {
  const locale = useLocale()

  if (typeof props.to === 'string') {
    return <Navigate {...props} to={`/${locale}${props.to}`} />
  }

  return <Navigate {...props} />
}

const router = wrapCreateBrowserRouter(createBrowserRouter)(
  createRoutesFromElements(
    <Route id="MainRouteLayout" element={<MainRouteLayout />}>
      <Route id="MaintenanceModeMiddleware" element={<MaintenanceModeMiddleware />}>
        <Route id="LocaleMiddleware" path=":locale?" element={<LocaleMiddleware />}>
          <Route id="AuthenticatedLayout" element={<AuthenticatedLayout />}>
            {env.APP_ENV === 'LOCAL' && (
              <Route
                path="dev"
                lazy={async () => {
                  const { default: DevPage } = await import('./pages/Authenticated/DevPage')
                  return { element: <DevPage /> }
                }}
              />
            )}
            {env.APP_ENV !== 'PRODUCTION' && (
              <Route
                path="swagger"
                lazy={async () => {
                  const { default: SwaggerPage } = await import('./pages/Authenticated/SwaggerPage')
                  return { element: <SwaggerPage /> }
                }}
              />
            )}
            <Route
              path="profile/settings"
              lazy={async () => {
                const { default: ProfileSettingsLayout } = await import(
                  './pages/Authenticated/profile/settings/ProfileSettingsLayout'
                )
                return { element: <ProfileSettingsLayout /> }
              }}
            >
              <Route index element={<Navigate replace to="general" />} />
              <Route
                path="general"
                lazy={async () => {
                  const { default: ProfileGeneralSettingsPage } = await import(
                    './pages/Authenticated/profile/settings/ProfileGeneralSettingsPage'
                  )
                  return { element: <ProfileGeneralSettingsPage /> }
                }}
              />
              <Route
                path="notifications"
                lazy={async () => {
                  const { default: ProfileNotificationSettingsPage } = await import(
                    './pages/Authenticated/profile/settings/ProfileNotificationSettingsPage'
                  )
                  return { element: <ProfileNotificationSettingsPage /> }
                }}
              />
            </Route>

            <Route
              path="profile"
              lazy={async () => {
                const { default: ProfileLayout } = await import('./pages/Authenticated/profile/ProfileLayout')
                return { element: <ProfileLayout /> }
              }}
            >
              <Route index element={<NavigateWithLocale replace to="/profile/personal" />} />
              <Route
                path="personal"
                lazy={async () => {
                  const { default: ProfilePersonalInformationPage } = await import(
                    './pages/Authenticated/profile/ProfilePersonalInformationPage'
                  )
                  return { element: <ProfilePersonalInformationPage /> }
                }}
              />
              <Route
                path="contact"
                lazy={async () => {
                  const { default: ProfileContactInformationPage } = await import(
                    './pages/Authenticated/profile/ProfileContactInformationPage'
                  )
                  return { element: <ProfileContactInformationPage /> }
                }}
              />
            </Route>

            <Route
              path="notifications"
              lazy={async () => {
                const { default: NotificationsPage } = await import(
                  './pages/Authenticated/notifications/NotificationsPage'
                )
                return { element: <NotificationsPage /> }
              }}
            />
            <Route
              path="notifications/redirect"
              lazy={async () => {
                const { default: NotificationsRedirectPage } = await import(
                  './pages/Authenticated/notifications/NotificationsRedirectPage'
                )
                return { element: <NotificationsRedirectPage /> }
              }}
            />

            <Route
              path="profile/auth-log"
              lazy={async () => {
                const { default: ProfileAuthorizationLogPage } = await import(
                  './pages/Authenticated/profile/ProfileAuthorizationLogPage'
                )
                return { element: <ProfileAuthorizationLogPage /> }
              }}
            />
            <Route
              path="news"
              lazy={async () => {
                const { default: NewsPage } = await import('./pages/Authenticated/news/NewsPage')
                return { element: <NewsPage /> }
              }}
            />
            <Route
              path="news/:newsId"
              lazy={async () => {
                const { default: SingleNewsPage } = await import('./pages/Authenticated/news/SingleNewsPage')
                return { element: <SingleNewsPage /> }
              }}
            />
            <Route
              path="library"
              lazy={async () => {
                const { default: LibraryPage } = await import('./pages/Authenticated/LibraryPage')
                return { element: <LibraryPage /> }
              }}
            />
            <Route
              path="messages"
              lazy={async () => {
                const { default: MessagesLayout } = await import('./pages/Authenticated/messages/MessagesLayout')
                return { element: <MessagesLayout /> }
              }}
            >
              <Route
                path="inbox"
                lazy={async () => {
                  const { default: MessagesInboxPage } = await import(
                    './pages/Authenticated/messages/MessagesInboxPage'
                  )
                  return { element: <MessagesInboxPage /> }
                }}
              />
              <Route
                path="outbox"
                lazy={async () => {
                  const { default: MessagesOutboxPage } = await import(
                    './pages/Authenticated/messages/MessagesOutboxPage'
                  )
                  return { element: <MessagesOutboxPage /> }
                }}
              />
            </Route>
            <Route
              path="messages/reply/:messageId/:semesterId"
              lazy={async () => {
                const { default: MessageReplyPage } = await import('./pages/Authenticated/messages/MessageReplyPage')
                return { element: <MessageReplyPage /> }
              }}
            />
            <Route
              path="messages/:messageId/:semesterId"
              lazy={async () => {
                const { default: MessagePage } = await import('./pages/Authenticated/messages/MessagePage')
                return { element: <MessagePage /> }
              }}
            />
            <Route
              path="messages/compose/:uid"
              lazy={async () => {
                const { default: MessageComposePage } = await import(
                  './pages/Authenticated/messages/MessageComposePage'
                )
                return { element: <MessageComposePage /> }
              }}
            />
            <Route
              path="messages/:messageId/:semesterId/export"
              lazy={async () => {
                const { default: MessageExportPage } = await import('./pages/Authenticated/messages/MessageExportPage')
                return { element: <MessageExportPage /> }
              }}
            />
            <Route
              path="users/:uid"
              lazy={async () => {
                const { default: UserPage } = await import('./pages/Authenticated/UserPage')
                return { element: <UserPage /> }
              }}
            />

            <Route path="student" id="UserProfileStudent" handle={{ profileType: 'UserProfileStudent' }}>
              <Route
                id="StudentHomePage"
                index
                lazy={async () => {
                  const { default: StudentHomePage } = await import('./pages/Authenticated/student/StudentHomePage')
                  return { element: <StudentHomePage /> }
                }}
              />

              <Route
                path="billing"
                lazy={async () => {
                  const { default: StudentBillingLayout } = await import(
                    './pages/Authenticated/student/billing/StudentBillingLayout'
                  )
                  return { element: <StudentBillingLayout /> }
                }}
              >
                <Route index element={<Navigate to="balance" />} />
                <Route
                  path="balance"
                  lazy={async () => {
                    const { default: StudentBillingBalancePage } = await import(
                      './pages/Authenticated/student/billing/StudentBillingBalancePage'
                    )
                    return { element: <StudentBillingBalancePage /> }
                  }}
                />
                <Route
                  path="deposits"
                  lazy={async () => {
                    const { default: StudentBillingDepositsPage } = await import(
                      './pages/Authenticated/student/billing/StudentBillingDepositsPage'
                    )
                    return { element: <StudentBillingDepositsPage /> }
                  }}
                />
                <Route
                  path="schedule"
                  lazy={async () => {
                    const { default: StudentBillingSchedulePage } = await import(
                      './pages/Authenticated/student/billing/StudentBillingSchedulePage'
                    )
                    return { element: <StudentBillingSchedulePage /> }
                  }}
                />
                <Route
                  path="instruction"
                  lazy={async () => {
                    const { default: StudentBillingInstructionPage } = await import(
                      './pages/Authenticated/student/billing/StudentBillingInstructionPage'
                    )
                    return { element: <StudentBillingInstructionPage /> }
                  }}
                />
              </Route>

              <Route
                path="calendar"
                lazy={async () => {
                  const { default: StudentCalendarPage } = await import(
                    './pages/Authenticated/student/calendar/StudentCalendarPage'
                  )
                  return { element: <StudentCalendarPage /> }
                }}
              />
              <Route
                path="card"
                lazy={async () => {
                  const { default: StudentAchievementPage } = await import(
                    './pages/Authenticated/student/achievements/StudentAchievementPage'
                  )
                  return { element: <StudentAchievementPage /> }
                }}
              />
              <Route
                path="card/export"
                lazy={async () => {
                  const { default: StudentAchievementExportPage } = await import(
                    './pages/Authenticated/student/achievements/StudentAchievementExportPage'
                  )
                  return { element: <StudentAchievementExportPage /> }
                }}
              />
              <Route
                path="catalog"
                lazy={async () => {
                  const { default: StudentCatalogPage } = await import(
                    './pages/Authenticated/student/catalog/StudentCatalogPage'
                  )
                  return { element: <StudentCatalogPage /> }
                }}
              />
              <Route
                path="choices"
                lazy={async () => {
                  const { default: StudentChoicesPage } = await import(
                    './pages/Authenticated/student/choices/StudentChoicesPage'
                  )
                  return { element: <StudentChoicesPage /> }
                }}
              />
              <Route
                path="courses/:id"
                lazy={async () => {
                  const { default: StudentCourseLayout } = await import(
                    './pages/Authenticated/student/courses/StudentCourseLayout'
                  )
                  return { element: <StudentCourseLayout /> }
                }}
              >
                <Route
                  path="groups"
                  lazy={async () => {
                    const { default: StudentCourseGroupsPage } = await import(
                      './pages/Authenticated/student/courses/StudentCourseGroupsPage'
                    )
                    return { element: <StudentCourseGroupsPage /> }
                  }}
                />
                <Route
                  path="syllabus"
                  lazy={async () => {
                    const { default: StudentCourseSyllabusPage } = await import(
                      './pages/Authenticated/student/courses/StudentCourseSyllabusPage'
                    )
                    return { element: <StudentCourseSyllabusPage /> }
                  }}
                />
                <Route
                  path="classmates"
                  lazy={async () => {
                    const { default: StudentCourseClassmatesPage } = await import(
                      './pages/Authenticated/student/courses/StudentCourseClassmatesPage'
                    )
                    return { element: <StudentCourseClassmatesPage /> }
                  }}
                />
                <Route
                  path="materials"
                  lazy={async () => {
                    const { default: StudentCourseMaterialsPage } = await import(
                      './pages/Authenticated/student/courses/StudentCourseMaterialsPage'
                    )
                    return { element: <StudentCourseMaterialsPage /> }
                  }}
                />
                <Route
                  path="posts"
                  lazy={async () => {
                    const { default: StudentCourseDiscussionPage } = await import(
                      './pages/Authenticated/student/courses/discussion/StudentCourseDiscussionPage'
                    )
                    return { element: <StudentCourseDiscussionPage /> }
                  }}
                />
                <Route
                  path="scores"
                  lazy={async () => {
                    const { default: StudentCourseScoresPage } = await import(
                      './pages/Authenticated/student/courses/StudentCourseScoresPage'
                    )
                    return { element: <StudentCourseScoresPage /> }
                  }}
                />
              </Route>

              <Route
                path="courses"
                lazy={async () => {
                  const { default: MyCoursesLayout } = await import(
                    './pages/Authenticated/student/mycourses/MyCoursesLayout'
                  )
                  return { element: <MyCoursesLayout /> }
                }}
              >
                <Route index element={<Navigate replace to="all" />} />
                <Route
                  path="all"
                  lazy={async () => {
                    const { default: StudentCoursesAllPage } = await import(
                      './pages/Authenticated/student/mycourses/StudentCoursesAllPage'
                    )
                    return { element: <StudentCoursesAllPage /> }
                  }}
                />
                <Route
                  path="free"
                  lazy={async () => {
                    const { default: StudentCoursesFreePage } = await import(
                      './pages/Authenticated/student/mycourses/StudentCoursesFreePage'
                    )
                    return { element: <StudentCoursesFreePage /> }
                  }}
                />
                <Route
                  path="general"
                  lazy={async () => {
                    const { default: StudentCoursesGeneralPage } = await import(
                      './pages/Authenticated/student/mycourses/StudentCoursesGeneralPage'
                    )
                    return { element: <StudentCoursesGeneralPage /> }
                  }}
                />
                <Route
                  path="program/:id"
                  lazy={async () => {
                    const { default: StudentCoursesProgramPage } = await import(
                      './pages/Authenticated/student/mycourses/StudentCoursesProgramPage'
                    )
                    return { element: <StudentCoursesProgramPage /> }
                  }}
                />
                <Route
                  path="programs"
                  lazy={async () => {
                    const { default: StudentCoursesProgramsPage } = await import(
                      './pages/Authenticated/student/mycourses/StudentCoursesProgramsPage'
                    )
                    return { element: <StudentCoursesProgramsPage /> }
                  }}
                />
              </Route>

              <Route
                path="feedbacks"
                lazy={async () => {
                  const { default: StudentFeedbackLayout } = await import(
                    './pages/Authenticated/student/feedback/StudentFeedbackLayout'
                  )
                  return { element: <StudentFeedbackLayout /> }
                }}
              >
                <Route
                  index
                  lazy={async () => {
                    const { default: StudentFeedbacksPage } = await import(
                      './pages/Authenticated/student/feedback/StudentFeedbacksPage'
                    )
                    return { element: <StudentFeedbacksPage /> }
                  }}
                />
                <Route
                  path=":feedbackId"
                  lazy={async () => {
                    const { default: StudentFeedbackPage } = await import(
                      './pages/Authenticated/student/feedback/StudentFeedbackPage'
                    )
                    return { element: <StudentFeedbackPage /> }
                  }}
                />
                <Route
                  path="add"
                  lazy={async () => {
                    const { default: StudentFeedbackAddPage } = await import(
                      './pages/Authenticated/student/feedback/StudentFeedbackAddPage'
                    )
                    return { element: <StudentFeedbackAddPage /> }
                  }}
                />
              </Route>

              <Route
                path="programs"
                lazy={async () => {
                  const { default: StudentProgramsPage } = await import(
                    './pages/Authenticated/student/programs/StudentProgramsPage'
                  )
                  return { element: <StudentProgramsPage /> }
                }}
              />
              <Route
                path="rating"
                lazy={async () => {
                  const { default: StudentRatingPage } = await import(
                    './pages/Authenticated/student/rating/StudentRatingPage'
                  )
                  return { element: <StudentRatingPage /> }
                }}
              />
              <Route
                path="recommendations"
                lazy={async () => {
                  const { default: StudentRecommendationsPage } = await import(
                    './pages/Authenticated/student/recommendations/StudentRecommendationsPage'
                  )
                  return { element: <StudentRecommendationsPage /> }
                }}
              />
              <Route
                path="recommendations/:id"
                lazy={async () => {
                  const { default: StudentRecommendationPage } = await import(
                    './pages/Authenticated/student/recommendations/StudentRecommendationPage'
                  )
                  return { element: <StudentRecommendationPage /> }
                }}
              />
              <Route
                path="recommendations/:id/export"
                lazy={async () => {
                  const { default: StudentRecommendationsExportPage } = await import(
                    './pages/Authenticated/student/recommendations/StudentRecommendationsExportPage'
                  )
                  return { element: <StudentRecommendationsExportPage /> }
                }}
              />

              <Route
                path="resume"
                lazy={async () => {
                  const { default: StudentResumeLayout } = await import(
                    './pages/Authenticated/student/resume/StudentResumeLayout'
                  )
                  return { element: <StudentResumeLayout /> }
                }}
              >
                <Route index element={<Navigate replace to="personal" />} />
                <Route
                  path="additional"
                  lazy={async () => {
                    const { default: StudentResumeAdditionalPage } = await import(
                      './pages/Authenticated/student/resume/StudentResumeAdditionalPage'
                    )
                    return { element: <StudentResumeAdditionalPage /> }
                  }}
                />
                <Route
                  path="education"
                  lazy={async () => {
                    const { default: StudentResumeEducationPage } = await import(
                      './pages/Authenticated/student/resume/StudentResumeEducationPage'
                    )
                    return { element: <StudentResumeEducationPage /> }
                  }}
                />
                <Route
                  path="export"
                  lazy={async () => {
                    const { default: StudentResumeExportPage } = await import(
                      './pages/Authenticated/student/resume/StudentResumeExportPage'
                    )
                    return { element: <StudentResumeExportPage /> }
                  }}
                />
                <Route
                  path="personal"
                  lazy={async () => {
                    const { default: StudentResumePersonalPage } = await import(
                      './pages/Authenticated/student/resume/StudentResumePersonalPage'
                    )
                    return { element: <StudentResumePersonalPage /> }
                  }}
                />
                <Route
                  path="skills"
                  lazy={async () => {
                    const { default: StudentResumeSkillsPage } = await import(
                      './pages/Authenticated/student/resume/StudentResumeSkillsPage'
                    )
                    return { element: <StudentResumeSkillsPage /> }
                  }}
                />
                <Route
                  path="summary"
                  lazy={async () => {
                    const { default: StudentResumeSummaryPage } = await import(
                      './pages/Authenticated/student/resume/StudentResumeSummaryPage'
                    )
                    return { element: <StudentResumeSummaryPage /> }
                  }}
                />
                \
                <Route
                  path="trainings"
                  lazy={async () => {
                    const { default: StudentResumeTrainingsPage } = await import(
                      './pages/Authenticated/student/resume/StudentResumeTrainingsPage'
                    )
                    return { element: <StudentResumeTrainingsPage /> }
                  }}
                />
                <Route
                  path="work"
                  lazy={async () => {
                    const { default: StudentResumeWorkPage } = await import(
                      './pages/Authenticated/student/resume/StudentResumeWorkPage'
                    )
                    return { element: <StudentResumeWorkPage /> }
                  }}
                />
              </Route>
              <Route
                path="schedule"
                lazy={async () => {
                  const { default: StudentSchedulePage } = await import(
                    './pages/Authenticated/student/schedule/StudentSchedulePage'
                  )
                  return { element: <StudentSchedulePage /> }
                }}
              />
              <Route
                path="statements"
                lazy={async () => {
                  const { default: StudentStatementsPage } = await import(
                    './pages/Authenticated/student/statements/StudentStatementsPage'
                  )
                  return { element: <StudentStatementsPage /> }
                }}
              />
              <Route
                path="statements/add"
                lazy={async () => {
                  const { default: StudentStatementsAddPage } = await import(
                    './pages/Authenticated/student/statements/StudentStatementsAddPage'
                  )
                  return { element: <StudentStatementsAddPage /> }
                }}
              />
              <Route path="portfolio">
                <Route index element={<Navigate replace to="sections" />} />
                <Route
                  path="sections"
                  lazy={async () => {
                    const { default: StudentPortfolioSectionsPage } = await import(
                      './pages/Authenticated/student/portfolio/StudentPortfolioSectionsPage'
                    )
                    return { element: <StudentPortfolioSectionsPage /> }
                  }}
                />

                <Route
                  path="sections/:id/works"
                  lazy={async () => {
                    const { default: StudentPortfolioLayout } = await import(
                      './pages/Authenticated/student/portfolio/StudentPortfolioLayout'
                    )
                    return {
                      element: <StudentPortfolioLayout />,
                    }
                  }}
                >
                  <Route index element={<Navigate replace to="listing" />} />
                  <Route
                    path="listing"
                    lazy={async () => {
                      const { default: StudentPortfolioSectionPage } = await import(
                        './pages/Authenticated/student/portfolio/StudentPortfolioSectionPage'
                      )
                      return { element: <StudentPortfolioSectionPage /> }
                    }}
                  />
                  <Route
                    path="scores"
                    lazy={async () => {
                      const { default: StudentPortfolioSectionScoresPage } = await import(
                        './pages/Authenticated/student/portfolio/StudentPortfolioSectionScoresPage'
                      )
                      return { element: <StudentPortfolioSectionScoresPage /> }
                    }}
                  />
                  <Route
                    path="history"
                    lazy={async () => {
                      const { default: StudentPortfolioSectionHistoryPage } = await import(
                        './pages/Authenticated/student/portfolio/StudentPortfolioSectionHistoryPage'
                      )
                      return { element: <StudentPortfolioSectionHistoryPage /> }
                    }}
                  />
                </Route>

                <Route
                  path="sections/:id/works/listing/add"
                  lazy={async () => {
                    const { default: StudentPortfolioWorkAddPage } = await import(
                      './pages/Authenticated/student/portfolio/StudentPortfolioWorkAddPage'
                    )
                    return { element: <StudentPortfolioWorkAddPage /> }
                  }}
                />
                <Route
                  path="sections/:sectionId/works/:workId"
                  lazy={async () => {
                    const { default: StudentPortfolioWorkPage } = await import(
                      './pages/Authenticated/student/portfolio/StudentPortfolioWorkPage'
                    )
                    return { element: <StudentPortfolioWorkPage /> }
                  }}
                />
              </Route>
            </Route>
            <Route path="lecturer" id="UserProfileLecturer" handle={{ ProfileType: 'UserProfileLecturer' }}>
              <Route
                id="LecturerHomePage"
                index
                lazy={async () => {
                  const { default: LecturerHomePage } = await import('./pages/Authenticated/lecturer/LecturerHomePage')
                  return { element: <LecturerHomePage /> }
                }}
              />
              <Route
                path="calendar"
                lazy={async () => {
                  const { default: LecturerCalendarPage } = await import(
                    './pages/Authenticated/lecturer/calendar/LecturerCalendarPage'
                  )
                  return { element: <LecturerCalendarPage /> }
                }}
              />

              <Route
                path="courses/teaches"
                lazy={async () => {
                  const { default: LecturerCoursesPage } = await import(
                    './pages/Authenticated/lecturer/courses//LecturerCoursesPage'
                  )
                  return { element: <LecturerCoursesPage /> }
                }}
              />
              <Route
                path="courses/manage"
                lazy={async () => {
                  const { default: LecturerSyllabusesPage } = await import(
                    './pages/Authenticated/lecturer/syllabuses/LecturerSyllabusesPage'
                  )
                  return { element: <LecturerSyllabusesPage /> }
                }}
              />
              <Route
                path="courses/:courseId"
                lazy={async () => {
                  const { default: LecturerCourseLayout } = await import(
                    './pages/Authenticated/lecturer/courses/LecturerCourseLayout'
                  )
                  return { element: <LecturerCourseLayout /> }
                }}
              >
                <Route index element={<Navigate to="scores" />} />
                <Route
                  path="scores"
                  lazy={async () => {
                    const { default: LecturerCoursesScoresPage } = await import(
                      './pages/Authenticated/lecturer/courses/scores/LecturerCoursesScoresPage'
                    )
                    return { element: <LecturerCoursesScoresPage /> }
                  }}
                />
                <Route
                  path="syllabus"
                  lazy={async () => {
                    const { default: LecturerCoursesSyllabusPage } = await import(
                      './pages/Authenticated/lecturer/courses/LecturerCoursesSyllabusPage'
                    )
                    return { element: <LecturerCoursesSyllabusPage /> }
                  }}
                />
                <Route path="discussions">
                  <Route
                    index
                    lazy={async () => {
                      const { default: LectureCoursesDiscussionsPage } = await import(
                        './pages/Authenticated/lecturer/courses/discussions/LectureCoursesDiscussionsPage/LectureCoursesDiscussionsPage'
                      )
                      return { element: <LectureCoursesDiscussionsPage /> }
                    }}
                  />
                  <Route
                    path="add"
                    lazy={async () => {
                      const { default: LectureCoursesDiscussionsAddPage } = await import(
                        './pages/Authenticated/lecturer/courses/discussions/LectureCoursesDiscussionsAddPage'
                      )
                      return { element: <LectureCoursesDiscussionsAddPage /> }
                    }}
                  />
                  <Route
                    path="edit/:postId"
                    lazy={async () => {
                      const { default: LectureCoursesDiscussionsEditPage } = await import(
                        './pages/Authenticated/lecturer/courses/discussions/LectureCoursesDiscussionsEditPage'
                      )
                      return { element: <LectureCoursesDiscussionsEditPage /> }
                    }}
                  />
                </Route>
                <Route path="materials">
                  <Route
                    index
                    lazy={async () => {
                      const { default: LecturerCoursesMaterialsPage } = await import(
                        './pages/Authenticated/lecturer/courses/trainingMaterials/LecturerCoursesMaterialsPage'
                      )
                      return { element: <LecturerCoursesMaterialsPage /> }
                    }}
                  />
                  <Route
                    path="add"
                    lazy={async () => {
                      const { default: LecturerCoursesMaterialsAddPage } = await import(
                        './pages/Authenticated/lecturer/courses/trainingMaterials/LecturerCoursesMaterialsAddPage'
                      )
                      return { element: <LecturerCoursesMaterialsAddPage /> }
                    }}
                  />
                  <Route
                    path="edit/:fileId"
                    lazy={async () => {
                      const { default: LecturerCoursesMaterialsEditPage } = await import(
                        './pages/Authenticated/lecturer/courses/trainingMaterials/LecturerCoursesMaterialsEditPage'
                      )
                      return { element: <LecturerCoursesMaterialsEditPage /> }
                    }}
                  />
                </Route>
                <Route
                  path="teaching-groups/export"
                  lazy={async () => {
                    const { default: LecturerGroupsExportPage } = await import(
                      './pages/Authenticated/lecturer/courses/LecturerGroupsExportPage'
                    )
                    return { element: <LecturerGroupsExportPage /> }
                  }}
                />
              </Route>
              <Route path="portfolio">
                <Route index element={<Navigate replace to="sections" />} />
                <Route
                  path="sections"
                  lazy={async () => {
                    const { default: LecturerPortfolioSectionsPage } = await import(
                      './pages/Authenticated/lecturer/portfolio/LecturerPortfolioSectionsPage'
                    )
                    return { element: <LecturerPortfolioSectionsPage /> }
                  }}
                />
                <Route
                  path="sections/:id/works"
                  lazy={async () => {
                    const { default: LecturerPortfolioLayout } = await import(
                      './pages/Authenticated/lecturer/portfolio/LecturerPortfolioLayout'
                    )
                    return {
                      element: <LecturerPortfolioLayout />,
                    }
                  }}
                >
                  <Route index element={<Navigate replace to="pending" />} />
                  <Route
                    path="pending"
                    lazy={async () => {
                      const { default: LecturerPortfolioSectionWorksPage } = await import(
                        './pages/Authenticated/lecturer/portfolio/LecturerPortfolioSectionWorksPage'
                      )
                      return { element: <LecturerPortfolioSectionWorksPage /> }
                    }}
                  />
                  <Route
                    path="evaluated"
                    lazy={async () => {
                      const { default: LecturerPortfolioSectionWorksPage } = await import(
                        './pages/Authenticated/lecturer/portfolio/LecturerPortfolioSectionWorksPage'
                      )
                      return { element: <LecturerPortfolioSectionWorksPage /> }
                    }}
                  />
                  <Route
                    path="rejected"
                    lazy={async () => {
                      const { default: LecturerPortfolioSectionWorksPage } = await import(
                        './pages/Authenticated/lecturer/portfolio/LecturerPortfolioSectionWorksPage'
                      )
                      return { element: <LecturerPortfolioSectionWorksPage /> }
                    }}
                  />
                </Route>
                <Route
                  path="sections/:sectionId/works/:workId"
                  lazy={async () => {
                    const { default: LecturerPortfolioWorkPage } = await import(
                      './pages/Authenticated/lecturer/portfolio/LecturerPortfolioWorkPage'
                    )
                    return { element: <LecturerPortfolioWorkPage /> }
                  }}
                />
                <Route
                  path="sections/:sectionId/works/:workId/edit"
                  lazy={async () => {
                    const { default: LecturerPortfolioEvaluationPage } = await import(
                      './pages/Authenticated/lecturer/portfolio/LecturerPortfolioEvaluationPage'
                    )
                    return { element: <LecturerPortfolioEvaluationPage /> }
                  }}
                />

                <Route
                  path="sections/:sectionId/works/:workId/reject"
                  lazy={async () => {
                    const { default: LecturerPortfolioRejectPage } = await import(
                      './pages/Authenticated/lecturer/portfolio/LecturerPortfolioRejectPage'
                    )
                    return { element: <LecturerPortfolioRejectPage /> }
                  }}
                />
              </Route>
              <Route
                path="feedbacks"
                lazy={async () => {
                  const { default: LecturerFeedbackLayout } = await import(
                    './pages/Authenticated/lecturer/feedback/LecturerFeedbackLayout'
                  )
                  return { element: <LecturerFeedbackLayout /> }
                }}
              >
                <Route
                  index
                  lazy={async () => {
                    const { default: LecturerFeedbacksPage } = await import(
                      './pages/Authenticated/lecturer/feedback/LecturerFeedbacksPage'
                    )
                    return { element: <LecturerFeedbacksPage /> }
                  }}
                />
                <Route
                  path=":feedbackId"
                  lazy={async () => {
                    const { default: LecturerFeedbackPage } = await import(
                      './pages/Authenticated/lecturer/feedback/LecturerFeedbackPage'
                    )
                    return { element: <LecturerFeedbackPage /> }
                  }}
                />
                <Route
                  path="add"
                  lazy={async () => {
                    const { default: LecturerFeedbackAddPage } = await import(
                      './pages/Authenticated/lecturer/feedback/LecturerFeedbackAddPage'
                    )
                    return { element: <LecturerFeedbackAddPage /> }
                  }}
                />
              </Route>
              <Route
                path="schedule"
                lazy={async () => {
                  const { default: LecturerSchedulePage } = await import(
                    './pages/Authenticated/lecturer/schedule/LecturerSchedulePage'
                  )
                  return { element: <LecturerSchedulePage /> }
                }}
              />

              <Route
                path="recommendation/:senderProfileId"
                lazy={async () => {
                  const { default: RecommendationReplyPage } = await import(
                    './pages/Authenticated/lecturer/recommendation/RecommendationReplyPage'
                  )
                  return { element: <RecommendationReplyPage /> }
                }}
              />
            </Route>
            <Route
              path="administrator"
              id="UserProfileAdministrator"
              handle={{ ProfileType: 'UserProfileAdministrator' }}
            >
              <Route
                id="AdministratorHomePage"
                index
                lazy={async () => {
                  const { default: AdministratorHomePage } = await import(
                    './pages/Authenticated/administrator/AdministratorHomePage'
                  )
                  return { element: <AdministratorHomePage /> }
                }}
              />
              <Route
                path="statistics/choices"
                lazy={async () => {
                  const { default: AdministratorStatisticsChoicesPage } = await import(
                    './pages/Authenticated/administrator/statistics/AdministratorStatisticsChoicesPage'
                  )
                  return { element: <AdministratorStatisticsChoicesPage /> }
                }}
              />
              <Route
                path="statistics"
                lazy={async () => {
                  const { default: AdministratorStatisticsDashboardPage } = await import(
                    './pages/Authenticated/administrator/statistics/AdministratorStatisticsDashboardPage'
                  )
                  return { element: <AdministratorStatisticsDashboardPage /> }
                }}
              />
              <Route path="users">
                <Route index element={<Navigate to="all" replace />} />
                <Route
                  path="all"
                  lazy={async () => {
                    const { default: AdministratorUsersAllPage } = await import(
                      './pages/Authenticated/administrator/users/AdministratorUsersAllPage'
                    )
                    return { element: <AdministratorUsersAllPage /> }
                  }}
                />
                <Route
                  path="administrators"
                  lazy={async () => {
                    const { default: AdministratorUsersAdminsPage } = await import(
                      './pages/Authenticated/administrator/users/AdministratorUsersAdminsPage'
                    )
                    return { element: <AdministratorUsersAdminsPage /> }
                  }}
                />
                <Route
                  path="lecturers"
                  lazy={async () => {
                    const { default: AdministratorUsersLecturersPage } = await import(
                      './pages/Authenticated/administrator/users/AdministratorUsersLecturersPage'
                    )
                    return { element: <AdministratorUsersLecturersPage /> }
                  }}
                />
                <Route
                  path="students"
                  lazy={async () => {
                    const { default: AdministratorUsersStudentsPage } = await import(
                      './pages/Authenticated/administrator/users/AdministratorUsersStudentsPage'
                    )
                    return { element: <AdministratorUsersStudentsPage /> }
                  }}
                />
              </Route>
            </Route>
          </Route>

          <Route
            id="UniversalLayout"
            lazy={async () => {
              const { default: UniversalLayout } = await import('./pages/UniversalLayout')
              return { element: <UniversalLayout /> }
            }}
          >
            <Route
              id="UrlRedirect"
              path="url"
              lazy={async () => {
                const { default: UrlRedirectPage } = await import('./pages/UrlRedirectPage')
                return { element: <UrlRedirectPage /> }
              }}
            />

            <Route
              id="RegistrationLayout"
              path="registration"
              lazy={async () => {
                const { default: RegistrationLayout } = await import('./pages/Registration/RegistrationLayout')
                return { element: <RegistrationLayout /> }
              }}
            >
              <Route index element={<Navigate replace to="lecturers" />} />
              <Route
                path="lecturers"
                lazy={async () => {
                  const { default: LecturersRegistration } = await import('./pages/Registration/LecturersRegistration')
                  return { element: <LecturersRegistration /> }
                }}
              />
              <Route
                path="masters"
                lazy={async () => {
                  const { default: MastersRegistration } = await import('./pages/Registration/MastersRegistration')
                  return { element: <MastersRegistration /> }
                }}
              />
            </Route>
            <Route
              path="application"
              lazy={async () => {
                const { default: MobileApplication } = await import('./pages/MobileApplication')
                return { element: <MobileApplication /> }
              }}
            />
            <Route
              path="faq"
              lazy={async () => {
                const { default: Faq } = await import('./pages/FAQ/Faq')
                return { element: <Faq /> }
              }}
            />
            <Route
              path="faq/:id"
              lazy={async () => {
                const { default: FaqQuestion } = await import('./pages/FAQ/FaqQuestion')
                return { element: <FaqQuestion /> }
              }}
            />

            <Route
              path="*"
              lazy={async () => {
                const { default: NotFoundPage } = await import('./pages/Authenticated/NotFoundPage')
                return { element: <NotFoundPage /> }
              }}
            />
          </Route>
        </Route>
      </Route>
    </Route>
  )
)

export default function Routing(): React.ReactElement | null {
  return <RouterProvider router={router} />
}
